<template>

  <VueHeadful
    :title="title"
    :description="description"/>

</template>

<script>
  import VueHeadful from "vue-headful";

  export default {
    name: "PageHeadHandler",

    components: {
      VueHeadful
    },

    data(){
      return {
      }
    },


    computed: {
      title() {
        return this.$store.getters['pageHead/getTitle'];
      },
      description() {
        return this.$store.getters['pageHead/getDescription']
      }
    }
  }
</script>

<style scoped>

</style>